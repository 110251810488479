import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from 'stimulus-use'
import { csrfToken } from "utils/csrf_token";
import { employeesTabulator } from '../../../../../../components/tabulator/employees';

export default class extends Controller {
  static targets = ['employeesList']
  static values = {
    url: String
  }

  connect() {
    useClickOutside(this);
    this._applyUrlQuery();
    this.employeesListTarget.innerHTML = document.getElementById('placeholder').innerHTML
    this._fetchEmployees();
  }

  export() {
    const tabulator = Tabulator.findTable('#employees')[0];
    tabulator.download('csv', 'employees.csv', { sheetName: 'export' }, 'active');
  }

  _fetchEmployees() {
    fetch(this.urlValue, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': csrfToken(),
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
    .then(response => response.json())
    .then(data => {
      this._loadTabulator(data.employees);
    }).catch(error => {
      console.log(error);
    })
  }

  _loadTabulator(data) {
    this.employeesListTarget.innerHTML = '<div id="employees"></div>';
    employeesTabulator(data);
  }

  _applyUrlQuery() {
    const urlParams = new URLSearchParams(window.location.search);
    const query = urlParams.get('query')
    if (query === null || query === '') { return };

    localStorage.setItem('employees_query', query)
    document.getElementById('search_employees').value = query;
  }
}
