function handleBoardeeAction(action) {
  if (action.classList.contains('first-action')) {
    var nextAction = action.nextElementSibling;
    if (nextAction) {
      setTimeout(() => {
        nextAction.classList.add('first-action');
        nextAction.querySelector('.action-handler .first-action-state').classList.remove('d-none');
        nextAction.querySelector('.action-handler .regular-action-state').classList.add('d-none');
      }, 1500);
    }
  }

  var timelineSection = action.closest('.timeline-section');
  if (timelineSection && timelineSection.querySelectorAll('.user-action').length === 1) {
    timelineSection.classList.add("fade-out");
    setTimeout(() => {
      if (action.classList.contains('first-action')) {
        var nextTimelineSection = timelineSection.nextElementSibling;
        if (nextTimelineSection) {
          var nextAction = nextTimelineSection.querySelector('.user-action');
          if (nextAction) {
            nextAction.classList.add('first-action');
            nextAction.querySelector('.action-handler .first-action-state').classList.remove('d-none');
            nextAction.querySelector('.action-handler .regular-action-state').classList.add('d-none');
          }
        }
      }
      timelineSection.remove();
    }, 1500);
  }

  action.classList.add('fade-out');
  setTimeout(() => {
    action.remove();
  }, 1500);
}

export { handleBoardeeAction };
