import { updateCounter } from 'components/tabulator/utils';

// this file takes care to get all the apply filter settings of tabulator
// They have to be all here in order to be able to be called from other places
// like the EnvironmentComponent where we use the component for different tabulator

// --- START FORM RESOURCES TABULATOR ---
export function applyFormResourcesFilters() {
  const tabulator = Tabulator.findTable('#form_resources')[0];

  let template_filters = localStorage.getItem('form_resources_templates');
  let template_ids = ['none'];
  if (template_filters) {
    template_ids = JSON.parse(template_filters);
    template_ids.push('none');
  }

  let unit_organization_filters = localStorage.getItem('form_resources_environments');
  let unit_organization_ids = ['none'];
  if (unit_organization_filters) {
    unit_organization_ids = JSON.parse(unit_organization_filters);
    unit_organization_ids.push('none');
  }

  let filters = [
    { field: 'name', type: 'like', value: String(getLocalStorageItem('form_resources_query')).trim() || '' },
    { field: 'mobilities', type: 'keywords', value: getLocalStorageItem('form_resources_mobilities').join(' ') }
  ];

  tabulator.setFilter(filters);
  if (unit_organization_filters) {
    tabulator.addFilter(unitOrganizationFilter, { unit_organization_ids: unit_organization_ids });
  }
  tabulator.addFilter(templateFilter, { template_ids: template_ids });
  updateCounter('form_resources');
}
// --- END FORM RESOURCES TABULATOR ---


// --- START FORMS TABULATOR ---
export function applyFormsFilters() {
  const tabulator = Tabulator.findTable('#forms')[0];

  let filters = this.buildInitialFilter()

  tabulator.setFilter(filters);
  updateCounter('forms');
}

export function buildInitialFilter(){
  const environmentsFilter = getLocalStorageItem('forms_environments');
  const formStatusesFilter = getLocalStorageItem('forms_form_statuses');
  const datesFilter = getLocalStorageItem('forms_dates');
  const query = getLocalStorageItem('forms_query') || '';
  const my_forms = getLocalStorageItem('my_forms_switch');

  let filters = [
    { field: 'environment_id', type: 'in', value: environmentsFilter.filter(el => Number(el)).map(Number) },
    { field: 'status_key', type: 'in', value: formStatusesFilter },
    { field: 'date', type: 'in', value: datesFilter },
    { field: 'collaborator_full_name', type: 'like', value: String(query).trim(), matchAll: true },
    { field: 'my_forms', type: 'in', value: my_forms }
  ];
  return filters;
}
// --- END FORMS TABULATOR ---


// --- START ACTIONS TABULATOR ---
export function applyActionsFilters() {
  const tabulator = Tabulator.findTable('#actions')[0];
  let type_filters = localStorage.getItem('actions_types');
  let employeeNameFilters = localStorage.getItem('actions_employee-names');
  let dateFilters = getLocalStorageItem('actions_dates');
  let types = ['none'];
  let employeeNames = ['none'];
  let dates = [];

  if (type_filters) { types = JSON.parse(type_filters); }
  if (employeeNameFilters) { employeeNames = JSON.parse(employeeNameFilters); }
  if (dateFilters.length > 0) { dates = dateFilters.map(date => moment(date, "YYYY-MM-DD").format("DD/MM/YYYY")) }

  let filters = [
    { field: 'type', type: 'in', value: types },
    { field: 'collaborator_name', type: 'in', value: employeeNames },
    { field: 'due_date', type: 'in', value: dates }
  ];

  tabulator.setFilter(filters);
  updateCounter('actions');
  document.querySelector("[data-action='click->tabulator-bulk-selection#uncheckAll']")?.click();
}
// --- END ACTIONS TABULATOR ---


// --- START TEMPLATES TABULATOR ---
export function applyTemplatesFilters() {
  const tabulator = Tabulator.findTable('#templates')[0];
  let filters = [
    { field: 'name', type: 'like', value: String(getLocalStorageItem('templates_query')).trim() || '' },
    { field: 'mobility', type: 'in', value: getLocalStorageItem('templates_mobilities') }
  ];
  if (tabulator.environment) {
    let unit_organization_filters = localStorage.getItem('templates_environments');
    let unit_organization_ids = ['none'];
    if (unit_organization_filters) {
      unit_organization_ids = JSON.parse(unit_organization_filters);
      unit_organization_ids.push('none');
    }
    filters.push(
      { field: unitOrganizationFilter, type: { unit_organization_ids: unit_organization_ids } }
    )
  }
  tabulator.setFilter(filters);
}
// --- END TEMPLATES TABULATOR ---

// --- START RESOURCES TABULATOR ---
export function applyResourcesFilters() {
  const tabulator = Tabulator.findTable('#resources')[0];
  tabulator.setFilter(loadResourceFilters());
  updateCounter('resources');
}

export function loadResourceFilters(){
  let template_filters = localStorage.getItem('resources_templates');
  let template_ids = ['none'];
  if (template_filters) {
    template_ids = JSON.parse(template_filters);
    template_ids.push('none');
  }

  let unit_organization_filters = localStorage.getItem('resources_environments');
  let unit_organization_ids = ['none'];
  if (unit_organization_filters) {
    unit_organization_ids = JSON.parse(unit_organization_filters);
    unit_organization_ids.push('none');
  }

  let filters = [
    { field: 'name', type: 'like', value: String(getLocalStorageItem('resources_query')).trim() || '' },
    { field: 'resource_type', type: 'in', value: getLocalStorageItem('resources_types') },
    { field: 'mobilities', type: 'keywords', value: getLocalStorageItem('resources_mobilities').join(' ') }
  ];

  filters.push({ field: templateFilter, type: { template_ids: template_ids }, value: '' });

  if (unit_organization_filters) {
    filters.push({ field: unitOrganizationFilter, type: { unit_organization_ids: unit_organization_ids }, value: '' });
  }
  return filters;
}
// --- END RESOURCES TABULATOR ---

// --- START EMPLOYEES TABULATOR ---
export function applyEmployeesFilters() {
  const tabulator = Tabulator.findTable('#employees')[0];
  tabulator.setFilter(employeesInitialFilter());
  updateCounter('employees');
}

export function employeesInitialFilter(){
  const environmentsFilter = getLocalStorageItem('employees_environments');
  const organizationsFilter = getLocalStorageItem('employees_organizations');
  const rolesFilter = getLocalStorageItem('employees_roles');
  const query = getLocalStorageItem('employees_query') || ''

  let filters = [
    { field: 'environment_id', type: 'in', value: environmentsFilter.filter(el => Number(el)).map(Number) },
    { field: 'organization_name', type: 'in', value: organizationsFilter },
    { field: 'role_name', type: 'in', value: rolesFilter }
  ];
  if (query != '') { filters.push({ field: queryEmployeesFilter, type: query, value: undefined }) }
  return filters;
}

// For the query of the employees we want to be able look data against several data
// - full_name
// - id
// - email
// - job_position
const queryEmployeesFilter = (data, query) => {
  const cleaned_query = String(query).trim();
  const attributes_to_look = ['employee_name', 'employee_email', 'employee_id', 'employee_external_id', 'job_position']
  return attributes_to_look.map(att => data[att]?.toLowerCase().includes(cleaned_query.toLowerCase()))
                           .includes(true);
};
// --- END EMPLOYEES TABULATOR ---

const getLocalStorageItem = itemKey => {
  const itemValue = localStorage.getItem(itemKey);

  let parsedValue;
  if (itemValue || itemValue == '') {
    if (typeof itemValue === 'string') {
      try {
        parsedValue = JSON.parse(itemValue);
        if (parsedValue.length == 0) { parsedValue = [] }
        if (parsedValue.length == 0 && !itemKey.includes('date')) { parsedValue.push('none') }
      } catch (error) {
        parsedValue = itemValue;
      }
    } else {
      parsedValue = itemValue;
    }
  } else {
    parsedValue = [];
  }

  return parsedValue;
}

function templateFilter(data, filterParams) {
  let data_template_ids = data.template_ids;
  if (data_template_ids.length == 0) {
    data_template_ids = [0];
  }
  let filter_template_ids = filterParams.template_ids.map(Number);
  return data_template_ids.some(item => filter_template_ids.includes(item));
}

function unitOrganizationFilter(data, filterParams) {
  let dataIds = data.unit_organization_ids;
  if (dataIds.length == 0) {
    dataIds = [0];
  }
  let filterIds = filterParams.unit_organization_ids.map(Number);
  return dataIds.some(item => filterIds.includes(item));
}
